.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gradient {
  /* background: linear-gradient(90deg, #d53369 0%, #daae51 100%); */
  /* background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%); */
  background-image: linear-gradient(90deg,  #4f46e5 0%, #c471f5 100%);
  
}

.SignInBtn{
  background-image: linear-gradient(to right bottom, rgb(168, 85, 247), rgb(99, 102, 241));
}

.CreateTeamBtn{
  background-image: linear-gradient(to left, #f7124b 10%, #ff0844 90%);}

.SignInBtnContents {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.backgroundGradient {
  background: #eeeeee
}

.advertisement{
  /* height:400px; */
}

.Hero_layout__2R3on{
  grid-template-columns:2fr minmax(min-content,1280px) 1fr;
  grid-template-rows:auto auto;
  /* max-width: 10px; */
  
}
/* @media (min-width:640px){
  .Hero_layout__2R3on{
    grid-template-columns:1fr minmax(min-content,640px) 1fr;
    grid-template-rows:auto auto
  }
}
@media (min-width:1024px){
  .Hero_layout__2R3on{
    grid-template-columns:2rem 1fr 2rem
  }
}
@media (min-width:1280px){
  .Hero_layout__2R3on{
    grid-template-columns:1fr 32rem 1fr;
    grid-template-rows:auto 2.25rem auto 2.25rem
  }
} */
.Hero_cardContainer__wrx9W{
  height:26.25rem
}
@media (min-width:640px){
  .Hero_cardContainer__wrx9W{
    height:26.25rem
  }
}
@media (min-width:1024px){
  /* .Hero_cardContainer__wrx9W{margin-left:calc(50% - 187px)
  } */
}
@media (min-width:1280px){
  /* .Hero_cardContainer__wrx9W{
    margin-left:0
  } */
}
@media (min-width:640px){
  .Hero_card__1yqlx{
    width:23.4375rem
  }
}
.Hero_codeWindow__1W0zH{
  height:auto;
  max-height:none
}
@media (min-width:768px){
  .Hero_codeWindow__1W0zH{
    height:33rem
  }
}
@media (min-width:1024px){
  .Hero_codeWindow__1W0zH{
    height:36rem
  }
}
@media (min-width:640px){
  .Footer_nav__2rFid{
    grid-template-rows:repeat(3,auto) 1fr
  }
}
@media (min-width:768px){
  .Footer_nav__2rFid{
    grid-template-rows:repeat(3,auto)
  }
}
@media (min-width:1024px){
  .Footer_nav__2rFid{
    grid-template-rows:auto 1fr
  }
}
.codeEditorStyle{
  white-space: initial;
}

.tailwindCodeStats{
  width: 180px; 
}

@media (min-width:640px){
  .tailwindCodeStats{
    width: 76px; 
  }
}

@media (min-width:768px){
  .tailwindCodeStats{
    width: 176px; 
  }
}

@media (min-width:1024px){
  .tailwindCodeStats{
    width: 576px; 
  }
}

/* .CodeWindow_root__1fMBP{
  height:28rem;
  max-height:60vh;
  max-width:90vh;
}
@media (min-width:768px){
  .CodeWindow_root__1fMBP{
    max-height:none;
  }
} */

.CodeWindow_root__1fMBP{height:28rem;max-height:60vh}@media (min-width:768px){.CodeWindow_root__1fMBP{height:36rem;max-height:none}}

.codeRotate75{
  --tw-rotate: -75deg;
  width: 25rem;
}

.codeRotate45{
  --tw-rotate: 60deg;
  width: 55vw
}

@media (min-width:300px){

  .codeRotate45{
    --tw-rotate: 25deg;
    width: 17rem;
  }
}

@media (min-width:640px){

  .codeRotate45{
    --tw-rotate: 25deg;
    width: 42rem;
  }
}

@media (min-width:1024px){

  .codeRotate45{
    --tw-rotate: 15deg;
    width: 66rem;
  }
}

@media (min-width:1280px){

  .codeRotate45{
    --tw-rotate: 7deg;
    width: calc(100vw - 50vw)
  }
}


@media (min-width:1536px){

  .codeRotate45{
    --tw-rotate: 7deg;
    width: 55vw
  }
}

@media (min-width:1280px){

  .lgLPadding{
    /* padding-left: 5%; */
    width: 50vw
  }
}

@media (min-width:1736px){

  .lgLPadding{
    padding-left: 15%;
  }
}





.GradientLockup_root__sBkgs{grid-template-columns:1fr minmax(min-content,640px) 1fr}@media (min-width:1024px){.GradientLockup_root__sBkgs{grid-template-columns:2rem 1fr 59.375% 2rem;grid-template-rows:auto 2.25rem auto 2.25rem}}@media (min-width:1280px){.GradientLockup_root__sBkgs{grid-template-columns:2rem 1fr 52.5% 2rem}}
@media (min-width:640px){.Customization_card__2LU79{height:23.375rem}}@media (min-width:1024px){.Customization_card__2LU79{height:27.5rem}}@media (min-width:1280px){.Customization_card__2LU79{height:23.375rem}}


.to-rose-500 {
  --tw-gradient-to: #f43f5e;
}

.frostedBlur:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.frostedBlur:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
}

.frostedBlur:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
}

.frostedBlur {
  background: inherit;
}

.frostedBlur:before {
  background: inherit;
}

/* 
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
} */


[data-placeholder]::after {
  content: " ";
  box-shadow: 0 0 50px 9px rgba(254,254,254);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%; 
  animation: load 2.5s  infinite;
}
@keyframes load {
  0%{ left: -100%}
  100%{ left: 150%}
}

[class^="term"] {
    height: 42vh;
    /* min-height: 300px; */
    margin: 0;
    /* padding: 0.7rem; */
    width: 100%;
    /* background-color: black; */
    color: white;
    font: 0.8rem Inconsolata, monospace;
    /* text-shadow: 0 0 5px #C8C8C8; */
    text-align: left;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.term::-webkit-scrollbar, .term > pre::-webkit-scrollbar, .term > pre > output::-webkit-scrollbar {
  display: none;
}
.term > pre, .term > pre > output {
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    -ms-overflow-style: none;
    scrollbar-width: none;
}
#animated_logo {
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    float: right;
    z-index: 0;
}
.revealContent {
    display: none;
    margin-bottom: 3rem;
}
